












































































import { Advert } from '@/includes/logic/Offers/types'
import AvatarsRow from '@/components/AvatarsRow.vue'

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { tooltipTrigger } from 'piramis-base-components/src/shared/utils/tooltipTrigger'
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import { SplitTags } from "piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers";
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import CashIcon from 'piramis-base-components/src/components/Icon/icons/CashIcon.vue';

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    CashIcon,
    AvatarsRow,
    SwitchInput
  },
  methods: {
    tooltipTrigger
  }
})
export default class OfferCard extends Mixins(ModelSetter) {
  @Prop() advert!: IterableListItem<Advert>

  @Prop() weekDayIndex!: number

  @Prop() index!: string | number

  @Emit()
  onRemoveClick() {
  }

  @Emit()
  onEditClick() {
  }

  @Emit()
  toggleActive(value: boolean) {
    this.isLoaderActive = true

    return {
      guid: this.advert.guid,
      value,
      closeLoader: this.closeLoader
    }
  }

  isLoaderActive = false

  get switchTitle() {
    if (this.advert.value.active) {
      return this.$t('offer_item_active_toggle_disable').toString()
    }

    return this.$t('offer_item_active_toggle_activate').toString()
  }

  get activeToggle() {
    return this.advert.value.active ?? true
  }

  set activeToggle(value: boolean) {
    this.toggleActive(value)
  }

  get offerRefAlias() {
    return `offer-title-${ this.index }`
  }

  get ruleText() {
    const { rules } = this.advert.value
    const tagsFreeRules = SplitTags(rules).trim()

    if (rules.length > 150) {
      return `${ tagsFreeRules.slice(0, 147) }...`
    }

    return tagsFreeRules
  }

  get price() {
    const products = this.$store.state.shopState.products

    if (products) {
      const product = products.find(p => p.id === this.advert.value.product_id)

      if (product) {
        return `${ product.cost } ${ product.currency }`
      }

      return ''
    }

    return ''
  }

  get advertTargets() {
    return this.$store.getters.boardTargetOptions
      .filter(o => this.advert.value.targets.some(t => t === o.value))
  }

  closeLoader() {
    this.isLoaderActive = false
  }

  checkLineClampExists<T extends Element>(el: T | undefined) {
    if (el) {
      return el.scrollHeight > el.clientHeight
    }
  }
}
