var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"offer-card h-full flex flex-col",attrs:{"body-style":{height: '100%'},"loading":_vm.isLoaderActive},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-icon',{key:"edit",attrs:{"type":"edit"},on:{"click":_vm.onEditClick}}),_c('a-icon',{key:"delete",attrs:{"type":"delete"},on:{"click":_vm.onRemoveClick}})]},proxy:true}])},[_c('div',{staticClass:"flex justify-between items-center mb-2"},[_c('div',{staticClass:"flex items-center gap-1"},[_c('cash-icon'),_vm._v(" "+_vm._s(_vm.price)+" ")],1),_c('switch-input',{staticClass:"mt-0 mb-0 pt-0 pb-0",attrs:{"title":_vm.switchTitle,"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'activeToggle',
          'titleVisible': false
        },
      }}})],1),_c('a-tooltip',{staticClass:"mb-2",attrs:{"placement":"topLeft","trigger":_vm.tooltipTrigger()},scopedSlots:_vm._u([(_vm.$refs[_vm.offerRefAlias] && _vm.checkLineClampExists(_vm.$refs[_vm.offerRefAlias]))?{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.advert.value.name)+" ")]},proxy:true}:null],null,true)},[_c('h3',{ref:_vm.offerRefAlias,staticClass:"mb-0 line-clamp-1"},[_vm._v(" "+_vm._s(_vm.advert.value.name)+" ")])]),_c('avatars-row',{staticClass:"overflow-x-auto pl-1 pb-1 mb-2",attrs:{"list":_vm.advertTargets}}),_c('a-icon',{attrs:{"type":"notification"}}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.ruleText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }