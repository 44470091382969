












































































































import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import OfferCard from '@/components/OfferCard.vue'
import { getFrontendAdverts, getRawAdverts } from "@/includes/logic/Offers/OffersLogic";

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    OfferCard,
    PageTitle,
    EmptyData,
    ConfigField
  }
})
export default class OffersList extends Mixins(ClipboardCopyMixin) {
  frontendOffers: ReturnType<typeof getFrontendAdverts> = []

  get activeAds() {
    if (this.$store.state.boardsState.activeBoard) {
      return this.frontendOffers.filter(o => o.value.active)
    }

    return []
  }

  get disabledAds() {
    if (this.$store.state.boardsState.activeBoard) {
      return this.frontendOffers.filter(o => !o.value.active)
    }

    return []
  }

  get activeBoard() {
    return this.$store.state.boardsState.activeBoard
  }

  get botLink() {
    const activeBoard = this.$store.state.boardsState.activeBoard
    
    if (activeBoard) {
      return `${ tgLoginLink(activeBoard.title) }?start=ads`
    }
  }

  toAdItem() {
    this.$router.push({
      name: "OffersListItem",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  toEdit(guid: string) {
    const idx = this.frontendOffers.findIndex(o => o.guid === guid)

    if (idx !== -1) {
      this.$router.push({
        name: "OffersListItem",
        params: {
          adId: (idx + 1).toString(),
          actionType: BaseItemViewAction.Edit
        }
      })
    }
  }

  removeAdWarn(guid: string) {
    this.$confirm({
      title: this.$t('delete_ad_warn_title').toString(),
      content: this.$t('delete_ad_warn_text').toString(),
      okText: this.$t('confirm').toString(),
      cancelText: this.$t('cancel').toString(),
      onOk: () => this.removeAd(guid)
    })
  }

  setStateAds(list: ReturnType<typeof getFrontendAdverts>) {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.boardsState.activeBoard!.config.ads = getRawAdverts(list)
      },
    })
  }

  removeAd(guid: string) {
    this.frontendOffers = this.frontendOffers.filter(o => o.guid !== guid)

    this.setStateAds(this.frontendOffers)

    this.$store.dispatch('savePostBoardConfig')
  }

  toggleOfferActive(payload: { guid: string, value: boolean, closeLoader: () => void }) {
    const offer = this.frontendOffers.find(o => o.guid === payload.guid)

    if (offer) {
      offer.value.active = payload.value

      this.setStateAds(this.frontendOffers)

      this.$store.dispatch('savePostBoardConfig')
        .finally(() => {
          payload.closeLoader()
        })
    }
  }

  created() {
    if (!this.$store.state.shopState.products) {
      this.$store.dispatch('getProducts', { board_key: this.$store.state.boardsState.activeBoard.board })
    }

    this.frontendOffers = getFrontendAdverts(this.$store.state.boardsState.activeBoard?.config?.ads ?? [])
  }
}
